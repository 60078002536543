.flex-tables {
  margin-bottom: $spacing-bottom;
  
  .flex-table {
    background-color: $lighter-gray;
    padding: 10px;

    .table {
      background-color: $white;
      border: 1px solid $light-gray;

      thead td {
        padding: 10px 5px;
      }

      tbody td {
        padding: 5px;
        border-top: 0;
      }
    }
  }
}