.commentlist {
  .review .star-rating {
    margin: 0 0 10px !important;
  }
}

.review {
  margin-bottom: $spacing-bottom-half;

  &--avatar {
    img {
      border-radius: 50px;
      margin-bottom: 6px;
    }
  }

  &--author {
    display: block;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &--comment {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: $single-gutter;
    height: 100%;

    &:before {
      content: "";
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent; 
      border-right: 10px solid $light-gray;
      position: absolute;
      top: calc(50% - 15px);
      left: 5px;
    }
  }

  &--reply {
    margin-top: 20px;

    .review--comment {
      &:before {
        top: -1px;
        left: 35px;
        transform: rotate(90deg);
      }
    }
  }

  .star-rating {
    margin: 0 auto;
  }
}

.ruk_rating_snippet {
  color: $light-yellow !important;
}

.ruk-rating-snippet-count {
  display: none !important;
}
