.download {
  margin-bottom: $spacing-bottom-half;
  padding-bottom: $spacing-bottom-half;
  border-bottom: 2px solid $light-gray;

  &:last-child {
    border-bottom: 0;
  }

  &-text {
    margin-bottom: $spacing-bottom-half;
    
    p:last-child {
      margin-bottom: 0;
    }
  }
}