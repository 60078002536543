.footer-misc {
  border-bottom: 2px solid $brand-primary;
  background-color: $dark-gray;
  color: $white;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
    border-bottom: 0;
  }
  
  .shop-pros {
    padding: $single-gutter 0;
    display: flex;
    flex-wrap: wrap;

    &--item {
      @extend .col-4, .col-md;

      @include media-breakpoint-down(md) {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
      }
    }

    strong {
      font-weight: 500;
    }

    .icon {
      margin-right: 6px;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        display: block;
        margin-bottom: 5px;
        margin-right: 0;
        color: $white;
      }
    }

    .fa-arrow-right {
      margin: 0 25px;
      display: none;
    }
  }
}

.logo {
  margin-bottom: 10px;
}

.social-icons {
  .follow-text {
    @include media-breakpoint-between(lg, xl) {
      display: none;
    }
  }
  
  a {
    color: $white;
    padding: 4px;
    text-align: center;
    margin-right: 4px;
    width: 28px;
    height: 28px;
    display: inline-block;
    

    @include media-breakpoint-between(sm, md) {
      width: 24px;
      height: 24px;
      padding: 2px;
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}



footer {
  background-color: $dark-gray;
  color: $white;
	padding: $single-gutter 0;

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 6px;

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      padding: 5px 0;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }

  .brand-logo {
    max-width: 120px;

    &-link {
      @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
      }
    }
  }
}

.copyright-text {
  font-size: 12px;
  text-align: center;
  background-color: darken($dark-gray, 2%);
  color: $white;
  padding: 10px 0;

  p {
    margin-bottom: 0;
  }
}