.cart-fixed {
	.cart_list {
		padding: 0;
		margin: 0;
		max-height: 75vh;
		overflow-x: scroll;
		padding-right: $single-gutter;

		&::-webkit-scrollbar {
		  -webkit-appearance: none;
		  width: 7px;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 4px;
		  background-color: $blue;
		  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
		}

		li {
			list-style-type: none;
			border-bottom: 2px solid $light-gray;
			padding: $single-gutter 0;

			img {
				@extend .img-fluid;
			}
		}
	}

	.totals .woocommerce-Price-amount {
		display: block;
		margin-bottom: $double-gutter;
		font-weight: 500;
	}

	.remove_from_cart_button {
		color: $light-gray;

		&:hover {
			color: $red;
		}
	}

	.product-title {
		margin-bottom: $single-gutter;
		color: $black;
		display: block;
		font-weight: 500;
	}

	.woocommerce-mini-cart__buttons {
		@extend .row;
	}

	.totals-wrap {
		display: flex;
		flex-wrap: wrap;
		padding: $single-gutter 0;

		div {
			flex-grow: 1;

			&.subtotal {
				font-weight: 500;
			}
		}
	}

	.benefits {
    margin-bottom: $single-gutter;

    @include media-breakpoint-down(sm) {
    	display: none;
    }
    
    li {
      border-bottom: 0;
      padding: 0;
    }
  }
}

.woocommerce-cart-form {
	@extend .table-responsive;

	.shop_table {
		@extend .table;
		margin-bottom: $spacing-bottom;
		width: 100%;

		&.product-price {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		td {
			vertical-align: middle;
			
			@include media-breakpoint-down(md) {
				padding: 0.25rem !important;
			}

			&.product-quantity {
				.quantity {
					input {
						@extend .form-control;
						width: 80px;
					}
				}
			}

			&.product-thumbnail {
				width: 300px;
				min-width: 100px;

				img {
					@extend .img-fluid;
				}
			}

			

			&.product-remove {
				a {
					border-radius: 50px;
					background-color: $red;
					color: $white;
					display: block;
			    width: 20px;
			    height: 20px;
			    text-align: center;
				}
			}


		}

		.button {
			@extend .float-right, .btn-primary;
		}

		.coupon {
			@extend .float-left;
			width: 50%;
			display: flex;
			flex-wrap: nowrap;

			label {
				display: none;
			}

			.input-text {
				@extend .form-control;
				display: inline-block;
				width: 59%;
				margin-right: 5px;
			}

			.button {
				@extend .btn-secondary;
				padding: 6px 10px;
			}
		}
	}
}

.cart-collaterals {
	@extend .row;
	margin-bottom: $spacing-bottom;

	.cross-sells {
		@extend .col-sm-8;
	}

	.cart_totals {
		@extend .col-sm-4, .offset-sm-8;

		.shop_table {
			@extend .table;

			td {
				text-align: right;
			}
		}

		.checkout-button {
			@extend .float-right;
		}
	}
}