.news-item {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: $spacing-bottom-half;

  a {
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  .btn {
    border-radius: 0px 0px 5px 5px;
  }

  h3 {
    font-size: 20px;

    @include media-breakpoint-only(lg) {
      font-size: 16px;
    }
  }

  &-excerpt {
    padding: $single-gutter;
    background-color: $lighter-gray;
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
    background-color: #e8e8e8;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &-meta {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -6px;
    margin-bottom: -6px;
    
    &--date {
      background-color: $white;
      color: $green;
      padding: 5px 10px;
      position: relative;
      z-index: 3;
      width: 80%;

      @include media-breakpoint-between(md, lg) {
        font-size: 11px;
      }

      &:after {
        content: "";
        border-right: 17px solid transparent;
        border-bottom: 32px solid $white;
        position: absolute;
        right: -17px;
        top: 0;
        bottom: 0;

        @include media-breakpoint-between(md, lg) {
          border-bottom: 28px solid $white;
        }
      }

      i {
        margin-right: 5px;
      }
    }

    &--color {
      background-color: $green;
      position: absolute;
      left: 0;
      right: 0;
      top: 6px;
      bottom: 0;
      width: 100% !important;
    }

    &--category {
      width: 20%;

      @include media-breakpoint-only(lg) {
        font-size: 12px;
      }

      span {
        padding: 2px 5px;
        display: block;
        margin-top: 6px;
        position: relative;
        z-index: 3;
        text-align: center;

        a {
          color: $white;
        }
      }

      i {
        margin-right: 5px;
      }
    }
  }

  &-lg {
    h3 {
      margin-bottom: $single-gutter;
    }

    .news-item-excerpt {
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      border-left: 0;
      height: 100%;
      border-radius: 0 5px 5px 0;
      position: relative;
      padding-bottom: 66px;
    }


    .new-item-footer {
      position: absolute;
      left: $single-gutter;
      right: $single-gutter;
      bottom: $single-gutter;

      .btn {
        border-radius: 5px;
      }

      .comments,
      .likes {
        background-color: $white;
        border: 1px solid $blue;
        border-radius: 3px;
        display: flex;

        @include media-breakpoint-down(md) {
          display: none;
        }

        &-icon {
          width: 40px;
          background-color: $blue;
          text-align: center;
          color: $white;
          padding: 6px;
        }

        &-text {
          flex-basis: calc(100% - 40px);
          text-align: center;
          padding: 6px $single-gutter;
        }
      }
    }
  }
}