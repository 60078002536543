/* Fonts */
$font-body: 'Raleway', sans-serif;
$font-heading: 'Montserrat', sans-serif;

/* Colour Variables */
$white: #FFF;
$lightest-gray: #f2f2f2;
$lighter-gray: #b3b3b3;
$light-gray: #d2d2d2;
$medium-gray: #8e8e8e;
$dark-gray: #3b3b3b;
$darker-gray: #23282d;
$green: #409420;
$light-green: #b7e6a6;
$light-yellow: #dea31e;
$light-orange: red;
$light-blue: #a2d3ea;

$brand-primary: $green;

/* Font Weights */
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;


/* Spacing */
$single-gutter: 15px !default;
$double-gutter: $single-gutter * 2 !default;

$spacing-bottom: 60px !default;
$spacing-bottom-half: $spacing-bottom / 2 !default;

// Carousel
$carousel-height: 400px !default;

// Bootstrap Overrides
$grid-columns:                12;
$grid-gutter-width:           30px;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xxs: 0,
  xs: 414px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1024px
);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1200px
);
