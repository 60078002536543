.commentlist {
	list-style-type: none;
	padding: 0;

	.comment_container {
		@extend .row;
		border-bottom: 1px solid $light-gray;

		&:last-child {
			border-bottom: none;
		}


		.gravatar-wrap {
			@extend .col-2, .col-sm-1;

			img {
				@extend .img-fluid;
			}
		}

		.comment-text {
			@extend .col-10, .col-sm-11;
		}
	}
}