.pricing-table {
  .nav {
    display: flex;
    border: 1px solid #dee2e6;
    border-bottom: 0;

    li {
      @extend .col, .p-0;
      text-align: center;

      a {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        font-size: 20px;
        
        &.active {
          background-color: $green;
          color: $white;
        }
      }

    }
  }

  .tab-content {
    background-color: $white;
    border: 1px solid #dee2e6;
    border-top: 0;
    padding: $spacing-bottom-half $single-gutter;
    margin-bottom: $spacing-bottom;

    .tables {
      margin-top: $spacing-bottom-half;
    }


    .product-item {
      text-align: center;
      // background-color: $white;
      padding-top: 15px !important;
      box-shadow: 0 0 4px rgba(0, 0, 0, .3);
      border-radius: 5px;

      &:first-child {
        border-left: 0;
      }

      // &:nth-child(odd) {
      //   background-color: $lightest-gray;
      // }
      
      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        // padding-bottom: 20px !important;
        // border-bottom: 4px solid $dark-gray;
        // background-color: $lightest-gray !important;
      }

      ul {
        list-style-position: inside;
        text-align: left;
      }

      &.recommended {
        // background-color: $lightest-gray;
        // border: 4px solid $green;
        padding-top: 0 !important;
      }

      h3 {
        font-size: 34px;
        color: $green;
        font-family: $font-body;
      }

      .bp {
        background-color: $light-green;
        margin-bottom: 6px;
        padding: 4px 0;
        font-weight: bold;
      }

      .buy-price {
        margin-bottom: 5px;
      }

      .amount {
        font-size: 22px;
        margin-bottom: 5px;
      }
      
      .per-year {
        .amount {
          font-size: 14px;
        }

        display: block;
        padding: 5px;
        text-align: center;
        background-color: $light-green;
        margin-bottom: 20px;
      }
      
      del .amount {
        color: $red;
        font-size: 14px !important;
      }
    }

  }
}