.woocommerce-products-header {
	text-align: center;
	padding: 0;
	margin-bottom: $spacing-bottom-half;

	h1 {
		margin-bottom: $spacing-bottom-half;
	}

	hr {
		margin-top: $spacing-bottom-half;
		margin-bottom: 0;
	}

	.category-description {
		margin-bottom: $spacing-bottom-half;
		overflow: hidden;
		position: relative;
		transition: height .5s ease-in-out;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 30px;
			background: -moz-linear-gradient(top, rgba(255, 255, 255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255, 255, 255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255, 255, 255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
		}

		&.active {
			height: 100%;
		}
	}

	.btn {
		&:after {
			content: "\f063";
			font-family: "Font Awesome 5 Pro";
			// @extend .fa;
			margin-left: 10px;
			transition: all .25s ease-in-out;
		}

		&.active {
			&:after {
				transform: rotate(-180deg);
			}
		}
	}
}

.list-info-wrap {
  background-color: $lighter-gray;
  padding: 10px $single-gutter;
  margin-bottom: $double-gutter;
  border-radius: 5px;

  p {
    margin-bottom: 0;
  }
}

ul.products {
	@extend .row;
	padding: 0;
	list-style-type: none;

	.product {
		@extend .col-12, .col-xs-6, .col-xl-3;
		margin-bottom: $double-gutter;

		&:before {
			display: none;
		}

		.woocommerce-loop-product__title {
			font-size: 20px;
			color: $dark-gray;
			font-weight: normal;
			margin-bottom: $single-gutter;

			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
		}

		.product-rating.top {
      display: none;
    }

    .product-list-price {
    	@extend .col-6, .col-sm-7;
    }

		ul.benefits {
			margin-bottom: $single-gutter;
			list-style-type: none;

			@include media-breakpoint-down(sm) {
				display: none;
			}

			li {
				color: $dark-gray;
			}
		}

		.woocommerce-LoopProduct-link {
			text-decoration: none;
			position: relative;
		
			.onsale {
				position: absolute;
			}
			
			img {
				@extend .img-fluid;
				margin-bottom: 15px;
			}

			.price {
				display: block;
				font-size: 17px;
				color: $dark-gray;
				font-weight: 500;
				margin-bottom: 0;

				@include media-breakpoint-down(md) {
					font-size: 16px;
				}

				@include media-breakpoint-between(xs, sm) {
					font-size: 13px;
				}

				.regular-price {
					font-size: 14px;
					color: $red;
					font-weight: $fw-light;
					font-style: italic;

					@include media-breakpoint-down(md) {
						font-size: 12px;
					}
				}

				del, ins {
					display: inline-block;
				}
			}

			.review-count {
				float: right;
				color: $dark-gray;
				line-height: 18px;
    		margin-left: 5px;
    		font-size: 12px;
			}

			.star-rating {
				margin-bottom: 0;
				float: right;

				@include media-breakpoint-down(sm) {
					float: none;
				}
			}

			.product-meta {
				margin-bottom: 6px;
			}
		}

		.btn {
			width: 100%;
		}
		
		.button {
			@extend .btn, .btn-primary;
		}

		@include media-breakpoint-down(sm) {
	    display: flex;
	    flex-wrap: wrap;
	    // border-bottom: 2px solid $light-gray;
	    padding-bottom: $spacing-bottom-half;

	    .product-image-wrap {
	      width: 40%;
	    }

	    .product-details-wrap {
	      width: 60%;

	      
				.product-list-price {
		    	width: 100% !important;
		    	flex: 0 0 100%;
		    	max-width: 100%;	
		    }

	      .title-wrap {
	        margin-bottom: 5px;
	      }

	      .product-rating {
	        margin-bottom: 10px;
	      }

	      .product-rating.bottom {
	        display: none;
	      }

	      .product-rating.top {
	        display: block;
	      }

	      .br-1 {
	        border-right: 0;
	      }
	    }
	  }
	}
}

.orderby {
	-webkit-appearance: none;
	background-color: transparent;
	border: 0;
}

.archive {
	
}