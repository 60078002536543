.top-header {
  background-color: $lighter-gray;
  padding: 6px;
  font-size: 14px;
  color: $white;

  i {
    margin-right: 10px;
  }

  select {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    color: #61676f;
  }
}

header.main-header {
  border-top: 4px solid $green;
  background-color: transparent;
  padding: 20px 0;
  transition: top .5s ease-in-out;
  color: $medium-gray;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all .25s ease-in-out;

  a {
    color: $white;
  }

  @include media-breakpoint-down(md) {
    padding: 0 5px !important;
    margin-bottom: $spacing-bottom-half;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 995;
  }

  .header-mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .header-desktop {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .mobile-menu-icon {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding: 0;
    color: $white;
  }

  .navbar-brand {
    max-width: 220px;
    margin-right: 0;
    transition: all .25s ease-in-out;

    @include media-breakpoint-down(sm) {
      max-width: 165px;
    }
  }

  &.live {
    background-color: #2d2d2d;
    padding: 10px 0;
    
    .navbar-brand {
      max-width: 160px; 
    }
  }

  .search {
    border-radius: 5px;
    border: 2px solid $brand-primary;
    display: flex;

    .vr {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-inputs {
      flex-basis: 90%;
      display: flex;
      align-items: center;

      input {
        flex-basis: 75%;
        border: 0;
        border-radius: 5px;
        height: 35px;
        outline: 0;
        padding: 0 10px;
        background-color: $white;

        @include media-breakpoint-down(md) {
          flex-basis: 100%;
        }
      }

      select {
        flex-basis: 25%;
        -webkit-appearance: none;
        padding: 0 30px 0 10px;
        background: transparent;
        background-image: url('../img/arrow-down.png');
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 15px;
        border: 0;
        border-radius: 0;
        outline: 0;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &-button {
      flex-basis: 10%;

      button {
        width: 101%;
        border-radius: 0;
        outline: 0;
      }
    }
  }

  .search-container {
    @include media-breakpoint-down(md) {
      background-color: $lighter-gray;
      padding: $single-gutter;
      margin-right: -$single-gutter;
      margin-left: -$single-gutter;
    }
  }

  .micro-nav {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      display: none;
    }

    a {
      display: block;
      text-align: center;
      padding: 0 10px;
      font-size: 10px;

      @include media-breakpoint-between(md, lg) {
        font-size: 11px;
      }

      &:hover {
        text-decoration: none;
        color: $green;
      }

      i {
        display: block;
        font-size: 14px;
        // margin-bottom: 8px;
      }
    }
  }
}

.mobile-nav {
  overflow: scroll;

  select {
    width: 100%;
  }

  .user-welcome {
    padding-bottom: $single-gutter;
  }
  
  .menu {
    padding: 0;
    margin-bottom: $single-gutter;
    list-style-type: none;

    li {
      margin-bottom: 10px;

      a {
        color: $black;
      }
    }
  }

  h4 {
    background-color: $lighter-gray;
    margin-left: -$single-gutter;
    margin-right: -$single-gutter;
    padding: 8px $single-gutter;
    font-size: 18px;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }
}