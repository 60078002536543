.flex-product-listing {
  margin-bottom: $spacing-bottom-half;

  ul.products {
    @extend .justify-content-center;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-bottom-half;
  }
}