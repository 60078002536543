.newsletter {
  background-color: $brand-primary;
  padding: $spacing-bottom-half 0;

  a {
    color: $white;
    text-decoration: underline;
  }

  form {
    padding: 20px 0;

    .btn {
      width: 100%;
      border-radius: 0 5px 5px 0;
      height: 40px;
      
      @include media-breakpoint-down(sm) {
        border-radius: 0 0 5px 5px;
      }
    }

    input {
      border-radius: 5px 0 0 5px;
      border: 0;
      height: 40px;
      padding: 0 10px;

      @include media-breakpoint-down(sm) {
        border-radius: 5px 5px 0 0;
      }
    }
  }

  h4 {
    color: $white;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $white;

    &:last-child {
      margin-bottom: 0;
    }
  }
}