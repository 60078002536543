.post-categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid $lighter-gray;
  border-bottom: 1px solid $lighter-gray;
  margin-bottom: $spacing-bottom-half;
  margin-top: $spacing-bottom-half;

  a {
    color: $black;
    display: block;
    padding: 10px 0;
    text-align: center;
  }

  .close {
    margin-right: 5px;
  }

  .active {
    a {
      color: $white;
    }
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}