.sidebar {
	.widget {
		margin-bottom: $double-gutter;

		ul {
			list-style-type: none;
			padding: 0;
		}
	}
}

.sidebar-wrap {
  margin-bottom: $spacing-bottom;
  
  @include media-breakpoint-down(md) {
    display: none;
  }
}