.br-1 {
  border-right: 1px solid $light-gray;
}

.vr {
  width: 2px;
  background-color: $light-gray;
  height: 75%;
}

.fill-link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mh {
  @include media-breakpoint-down(md) {
    display: none;
  }
}