.flex-image-gallery {
  margin-bottom: $spacing-bottom-half;

  img {
    margin-bottom: $single-gutter;
  }
  
  .caption {
    margin-bottom: $double-gutter;
  }
}