.related {
  margin-top: $spacing-bottom-half;
  
  h2 {
    margin-bottom: $spacing-bottom-half;
  }

  ul.products .product {
    @extend .col-12, .col-md-6, .col-xl-3;
  }
}