.woocommerce-pagination {
	margin-bottom: $spacing-bottom;
	text-align: center;

	@extend .offset-sm-2;

	.page-numbers {
		@extend .pagination;
		justify-content: center;

		li {
			@extend .page-item;
			border: 2px solid $green;

			&:first-child {
				border-radius: 5px 0 0 5px;
			}

			&:last-child {
				border-radius: 0 5px 5px 0;
			}

			a {
				border-radius: 0 !important;
			}

			&.active {
				* {
					background-color: $green;
    			border-color: $green;
    		}
			}

			* {
				@extend .page-link;
				border: 0;
				border-radius: 0 !important;

				&.current {
					background-color: $green;
					color: $white;
				}
			}
		}
	}
}

.navigation {
	margin: 0 auto;
	margin-bottom: $spacing-bottom;
	text-align: center;

	ul {
		@extend .pagination;
		justify-content: center;

		li {
			@extend .page-item;
			border: 2px solid $green;

			&:first-child {
				border-radius: 5px 0 0 5px;
			}

			&:last-child {
				border-radius: 0 5px 5px 0;
			}

			a {
				border-radius: 0 !important;
				color: $black;
			}

			&.active * {
				background-color: $green;
    		border-color: $green;
			}

			* {
				@extend .page-link;
				border: 0;
				border-radius: 0;

				&.current {
					background-color: $green;
					color: $white;
				}
			}
		}
	}
}