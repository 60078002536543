.full-width-image {
  margin-bottom: $spacing-bottom;

	img {
    height: auto;
  }
}

.full-width-image + .newsletter {
  margin-top: -$spacing-bottom;
}
