.flex-social {
  margin-bottom: $spacing-bottom;

  @include media-breakpoint-down(sm) {
    margin-bottom: $spacing-bottom-half;
  }

  a {
    color: $white;
    display: block;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, .2);
    width: 160px;
    padding: 30px 0;
    border-radius: 5px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      margin-bottom: 15px;
    }

    i {
      font-size: 80px;

      @include media-breakpoint-down(sm) {
        font-size: 60px;
      }
    }

    .icon-label {
      display: block;
      margin-top: 5px;
    }
  }
}