.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  &-dark {
    background-color: rgba(0, 0, 0, .5);
  }
}

.close {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.bgoverlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, .6);

  &.dark {
    background-color: rgba(0, 0, 0, .5);
  }
}

.fixed-screen {
  background-color: $white;
  width: 35vw;
  position: fixed;
  top: 0;
  right: -35vw;
  bottom: 0;
  z-index: 1000;
  transition: all .5s ease-in-out;
  padding: $double-gutter;

  &.active {
    right: 0;
  }

  @include media-breakpoint-down(md) {
    width: 75vw;
    right: -75vw;
    padding: 30px 15px;
  }

  @include media-breakpoint-down(sm) {
    width: 85vw;
    right: -85vw;
    padding: 30px 15px;
  }

  &-invert {
    width: 40vw;
    left: -40vw;
    right: auto;

    &.active {
      right: auto;
      left: 0;
    }

    @include media-breakpoint-down(md) {
      width: 75vw;
      left: -75vw;
      padding: 30px 15px;
    }

    @include media-breakpoint-down(sm) {
      width: 95vw;
      left: -95vw;
      padding: 30px 15px;
    }
  }
}

ul.benefits,
.key-benefits ul,
.woocommerce-product-details__short-description ul {
  list-style-type: none;
  padding-left: 20px;

  li {
    position: relative;

    &:before {
      content: "\f00c";
      @extend .fas;
      margin-right: 0px;
      position: absolute;
      left: -20px;
      font-size: 10px;
      top: 4px;
    }
  }
}

.page-header {
  margin-bottom: $spacing-bottom-half;
}

.share-buttons {
  margin-bottom: $spacing-bottom-half;

  &:before {
    content: "Share";
    display: block;
    margin-bottom: 6px;
    text-align: center;
  }

  .a2a_default_style a {
    display: inline-block;
    float: none;
  }
}

.has-bg {
  background-size: cover;
  background-position: center;
  padding: $spacing-bottom * 2 0;
  color: $white;
  margin-top: -$spacing-bottom;
  position: relative;

  h1 {
    font-size: 40px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .2);
  }
}