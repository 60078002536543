@import 'buttons';
@import 'cart';
@import 'checkout';
@import 'comments';
@import 'forms';
@import 'image-gallery';
// @import 'nav-tabs';
@import 'message';
@import 'pagination';
@import 'product-lists';
// @import 'ratings';
@import 'quantity';
@import 'related';
@import 'reviews';

.wc-tabs {
	display: none;
}

.wc-tab {
	margin-bottom: $spacing-bottom;
}

.woocommerce {
	.content-area {
		@extend .container;
	}
}

// Archive Pages
.woocommerce-result-count,
.woocommerce-ordering {
	@extend .col-sm-6;
}

.woocommerce-ordering {
	text-align: right;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.woocommerce-result-count {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

// Woocommerce Global Styles
.summary.entry-summary {
	@extend .col-12, .col-md-6;
}

.comment-reply-title {
	@extend h4;
}

// My Account
.woocommerce-Addresses {
	@extend .row;

	.col-1,
	.col-2 {
		@extend .col-6;
	}
}

