.comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-bottom;

  .comment-notes,
  .comment-form-comment {
    width: 100%;
  }

  .comment-form-author {
    padding-right: $single-gutter;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  .comment-form-email {
    padding-left: $single-gutter;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }

  .comment-form-author,
  .comment-form-email {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .comment-form-cookies-consent {
    display: none;
  }
}

.post-comment {
  border-bottom: 1px solid $light-gray;
  padding-bottom: $single-gutter;
  margin-bottom: $single-gutter;

  &--author {
    display: block;
    font-weight: 500;
    text-transform: capitalize;
  }

  &--date {
    display: block;
  }

  &-meta {
    margin-bottom: $single-gutter;
  }
}