.woocommerce {
	div.product {

		.woocommerce-product-gallery__trigger {
			display: none;
		}

		.woocommerce-product-gallery {
			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
		}
		
		.emoji {
			display: none !important;
		}
		
		div.images {
			@extend .col-12, .col-md-6;

			.zoomImg {
				background-color: white;
			}

			.woocommerce-product-gallery__image {
				width: 100px;
				margin-bottom: 10px;
				border: 1px solid $light-gray;
				border-radius: 5px;
				text-align: center;
				transition: border .25s ease-in-out;

				@include media-breakpoint-down(md) {
					float: left;
					margin-left: 5px;
					width: calc(25% - 5px);
				}

				&:first-child {
					float: right;
					width: calc(100% - 115px);
					margin-bottom: 0;
					border: 0;
					border-radius: 0;

					@include media-breakpoint-down(md) {
						float: none;
						width: 100%;
					}
				}
			}

			img {
				@extend .img-fluid;
			}
		}
	}
}

.outofstock {
	.product-image-wrap {
		position: relative;

		a {
			display: block;
			
			&:before {
				content: "";
				background-color: rgba(255, 255, 255, .6);
				background-image: url('../img/out-of-stock.png');
		    background-repeat: no-repeat;
				background-position: center;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 20;
			}
		}
	}

	.woocommerce-product-gallery__image {
		&:first-child {
			&:before {
				content: "";
				background-color: rgba(255, 255, 255, .6);
				background-image: url('../img/out-of-stock.png');
		    background-repeat: no-repeat;
				background-position: center;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 20;
			}
		}
	}
}