.quantity {
  input {
    width: 55px !important;
  }
}

.summary {
  .quantity {
    margin-bottom: $single-gutter;

    &:before {
      content: "Quantity";
      margin-right: $single-gutter;
    }
  }
}