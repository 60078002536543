body {
	font-family: $font-body;
  font-size: 16px;
  color: $dark-gray;
  background-color: $lightest-gray;
  
  &:not(.home) {
    padding-top: 140px;

    @include media-breakpoint-down(sm) {
      padding-top: 90px;
    }

    .main-header {
      @extend .live;
    }
  }

}

h1,
h2,
h3,
h4 {
	font-family: $font-heading;
  font-weight: $fw-medium;
}

h1 {
  font-size: 34px;

  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }

  &.page-heading {
    margin-bottom: $spacing-bottom-half;
  }
}

h2 {
  font-size: 28px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h3 {
  font-size: 22px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

h4 {
  font-size: 20px;
  
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

p + h1,
p + h2,
p + h3,
p + h4 {
  margin-top: 25px;
}

hr {
  border-color: $light-gray;
  border-width: 2px;
}

a {
  color: $brand-primary;
  transition: color .25s ease-in-out;

  &:hover {
    color: darken($brand-primary, 20%);
  }
}

button,
button:focus {
  outline: none;
}

blockquote {
  background-color: darken($lightest-gray, 5%);
  padding: $single-gutter;
  border-radius: $single-gutter;
  position: relative;
  padding-left: 80px;

  &:before {
    content: "\201C";
    color: rgba(99, 99, 99, 0.29);
    font-size: 190px;
    position: absolute;
    left: 5px;
    top: -56px;
    font-family: $font-heading;
  }
}

.blog-header {
  margin-bottom: $spacing-bottom;
  margin-top: -$spacing-bottom;
  color: $white;
  background-size: cover;
  padding: $spacing-bottom 0;

  .share-buttons {
    margin-bottom: 0;
  }
}

.post-meta {
  margin-bottom: $spacing-bottom-half;
  font-size: 22px;
}

.modal {
  .modal-header {
    background-color: $green;
    color: $white;
    text-align: center;

    h5 {
      width: 100%;
      font-weight: normal;
    }

    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      color: $white;
      font-size: 22px;
    }
  }

  .modal-footer {
    text-align: center;
    display: block;

    > :not(:last-child),
    > :not(:first-child) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .modal-dialog {
    @include media-breakpoint-up(md) {
      margin: 25vh auto;
    }
  }
}

.woocommerce-shipping-methods {
  list-style-type: none;
}

.mailchimp-newsletter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.select2-container .select2-selection--single {
  height: 34px !important;
  line-height: 34px !important;
}

.cwginstock-subscribe-form .panel-primary > .panel-heading {
  background-color: $blue !important;
  border-bottom: 0 !important;
  
  h4 {
    margin-bottom: 0;
  }
}

.cwginstock-subscribe-form .panel-primary {
  border-color: $blue !important;
}

.cwginstock-panel-body .cwgstock_button {
  @extend .btn, .btn-primary;
}

.blog-single-content {
  img {
    @extend .img-fluid;
  }
}

.buy-lei-cta {
  padding: 40px 0;
  background-color: $green;
  color: $white;
  background-size: cover;
  position: relative;
  background-position: center;

  .overlay {
    background-color: rgba(64, 148, 32, .7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }

  .cta-content {
    position: relative;
    z-index: 1000;
  }
}

.lei-cta {
  background-color: $green;
  color: $white;
  padding: 15px;
  border: 2px solid darken($green, 5%);
  margin-bottom: 15px;
}

#rlei-company-data-insert {
  position: absolute;
  z-index: 999;
  border: 2px solid $green;
  right: 15px;
  left: 15px;
  top: -17px;

  .rlei-ch-item {
    background-color: $white;
    display: block;
    padding: 6px;
    border-bottom: 2px solid $green;

    &:last-child {
      border-bottom: 0;
    }
  }
}