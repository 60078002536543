.widget {
  margin-bottom: $spacing-bottom-half;

  h3 {
    font-size: 16px;
    border-bottom: 1px solid $light-gray;
    padding-bottom: $single-gutter;
    margin-bottom: $single-gutter;
  }

  input[type="checkbox"] {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }

  label {
    display: block !important;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.rating-widget {
  color: $light-yellow;

  &.active {
    label {
      opacity: .4;
      transition: opacity .25s ease-in-out;

      &.selected {
        opacity: 1;
      }
    }
  }

  input {
    display: none;
  }

  .selected {

  }

  i {
    margin-right: 4px;
  }
}

.price_slider { 
  margin-bottom: $single-gutter;
  margin-right: $single-gutter;
}

.price_slider_amount {
  text-align: right;
  line-height: 2.4em;
  font-size: 0.8751em;

  button {
    display: none;
  }

  .price_label {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
  }

  span {
    display: inline-block;
  }

  .sep {
    color: $light-gray;
    margin: 0 15px;
  }

  .from,
  .to {
    border: 1px solid $light-gray;
    padding: 0 10px;
    line-height: 35px;
  }
}

.ui-slider {
  position: relative;
  text-align: left;

  .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border: 2px solid $blue;
    cursor: pointer;
    background: $white;
    outline: none;
    top: -6px;
  }

  .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size:.7em;
    display: block;
    border: 0;
    background: $blue;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.25) inset;
    border-radius: 3px;
  }
}

.price_slider_wrapper .ui-widget-content {
  border-radius: 1em;
  background: $light-gray;
}

.ui-slider-horizontal {
  height: 3px;

  .ui-slider-range {
    top: 0;
    height: 3px;
  }

  .ui-slider-range-min {
    left: -1px;
  }

  .ui-slider-range-max {
    right: -1px;
  }
}