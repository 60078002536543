.alternating {
  margin-bottom: $spacing-bottom;
  
  h1 {
    margin-bottom: $spacing-bottom-half;
  }

  img {
    margin-bottom: $single-gutter;
  }

  .row {
    padding-top: $double-gutter;
    padding-bottom: $double-gutter;
  }
}