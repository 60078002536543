input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
  padding: 6px;
  background-color: $white;
  border: 1px solid darken($lighter-gray, 10%);
  border-radius: 3px;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

textarea {
  padding: $single-gutter;
}

select {
  background: url('../img/caret-down.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 10px;
  appearance: none;
  background-color: $white;
  border: 1px solid darken($lighter-gray, 10%);
  height: 34px;
  padding: 0 20px 0 10px;
  border-radius: 3px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &.orderby,
  &.woocommerce-currency-switcher {
    background-color: $white !important;
    min-width: 100px;
  }
}

.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: $single-gutter;
  padding: 4px 0;
}

.wpcf7-submit {
  @extend .btn, .btn-primary;
}

.woocommerce-form-login__rememberme {
  margin-bottom: 15px;
  display: block;
}


