.flex-image-panels {
  margin-bottom: $spacing-bottom;

  .panel-item {
    background-size: cover;
    padding: $spacing-bottom-half;
    min-height: 280px;

    .mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .bgoverlay {
      display: none;

      @include media-breakpoint-only(lg) {
        display: block;
      }
    }

    h3 {
      font-size: 32px;
      text-shadow: 3px 0 5px $white;

      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }

    @include media-breakpoint-down(md) {
      // min-height: 165px;
      // background-position: center;
      min-height: initial;
      background: transparent !important;
      margin-bottom: 6px;
      padding: 0;
    }

    .panel-content {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .row {
      min-height: 280px;

      @include media-breakpoint-down(md) {
        min-height: 75px;
      }
    }

    .right {
      @extend .offset-xl-6;
    }
  }

  .panel-mobile-title {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}