.flex-contact {
  margin-bottom: $spacing-bottom;

  &-map {
    margin-bottom: $spacing-bottom;
    
    iframe {
      width: 100% !important;
    }
  }

  &-sep {
    margin-bottom: $spacing-bottom;
    text-align: center;

    p {
      margin-bottom: 0;
    }

  }
}