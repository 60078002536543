.flex-recommended-product-listing {
  margin-bottom: $spacing-bottom;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }

  .products {
    margin-bottom: 0;
    
    .product {
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        margin-bottom: $spacing-bottom-half;
      }

      .product-image-wrap,
      .product-details-wrap {
        width: 100%;

        .product-rating.bottom {
          display: block;
        }
      }
    }
  }
}