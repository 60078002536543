.flex-cards {
	margin-bottom: $spacing-bottom;

  .card {
    @include media-breakpoint-down(sm) {
      margin-bottom: $spacing-bottom-half;
    }
  }

  h4 {
    font-size: 18px;
  }

	img {
		margin-bottom: $single-gutter;
    // border-radius: 300px;
    border-bottom: 3px solid $green;
    // max-width: 300px;
	}
}