.woocommerce-Reviews {
  padding-top: $spacing-bottom-half;
  padding-bottom: $spacing-bottom-half;
  margin-bottom: $spacing-bottom;

  .comment-form {
    margin-bottom: 0;
  }
  
  .form-submit {
    margin-bottom: 0;
  }
}