.content-block {
	margin-bottom: $spacing-bottom;

  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-bottom-half;
  }

	.content {
    @include media-breakpoint-down(md) {
      margin-bottom: $spacing-bottom-half;

      &:last-child {
        margin-bottom: 0;
      }
    }

		p:last-child {
			margin-bottom: 0;
		}

    img {
      @extend .img-fluid;
    }
	}
}