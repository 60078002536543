.btn {
  border: 0;
  border-radius: 4px;
  font-weight: $fw-medium;
  padding: 6px 25px;

  @include media-breakpoint-down(sm) {
    padding: 6px 15px;
  }

  &:focus {
    box-shadow: none !important;
  }

  &-shadow {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  }

  &-primary {
    background-color: $brand-primary;

    &:hover {
      background-color: darken($brand-primary, 15%);
    }
  }

  &-orange {
    background-color: $orange;
    color: $white;

    &:hover {
      color: $white;
      background-color: darken($orange, 10%);
    }
  }

  &-secondary {
    background-color: $white;
    color: $brand-primary;
    border: 2px solid $brand-primary;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

.btn,
button {
  &.loading {
    position: relative;

    &:after {
      content: "Loading..";
      background-color: $white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: $brand-primary;
      padding: 6px;
      text-align: center;
    }
  }
}

// .single_add_to_cart_button,
// .add_to_cart_button {
//   &:before {
//     content: "\f07a";
//     @extend .fas;
//     margin-right: 10px;
//   }
// }

// .product_type_variable.add_to_cart_button {
//   &:before {
//     content: "\f737";
//     @extend .fas;
//     margin-right: 10px;
//   }
// }

.button {
  @extend .btn, .btn-primary;
}