.title-wrap {
  display: flex;
  margin-bottom: $single-gutter;

  h2 {
    flex-basis: calc(100% - 20px);
    margin-bottom: 0 !important;
  }

  .wishlist {
    background: transparent;
    border: 0;
    height: 25px;
    width: 35px;
    margin-bottom: 0;
    color: $light-orange;
    position: relative;
    z-index: 100;

    &.active {
      i:before {
        font-weight: 900;
      }
    }
  }
}

.wishlist-fixed {
  .wishlist-items {
    padding: 0;
    margin: 0;
    max-height: 75vh;
    overflow-x: scroll;
    padding-right: $single-gutter;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $blue;
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    li {
      list-style-type: none;
      border-bottom: 2px solid $light-gray;
      padding: $single-gutter 0;

      img {
        @extend .img-fluid;
      }
    }

    .benefits {
      margin-bottom: $single-gutter;

      @include media-breakpoint-down(sm) {
        display: none;
      }
      
      li {
        border-bottom: 0;
        padding: 0;
      }
    }
  }

  .totals .woocommerce-Price-amount {
    display: block;
    margin-bottom: $double-gutter;
    font-weight: 500;
  }

  .remove_from_cart_button {
    color: $light-gray;

    &:hover {
      color: $red;
    }
  }

  .product-title {
    margin-bottom: $single-gutter;
    color: $black;
    display: block;
    font-weight: 500;
  }

  .woocommerce-mini-cart__buttons {
    @extend .row;
  }

  .totals-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: $single-gutter 0;

    div {
      flex-grow: 1;

      &.subtotal {
        font-weight: 500;
      }
    }
  }
}