.flex-icon-cards {
  margin-bottom: $spacing-bottom;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .card {
    background-color: $white;
    padding: $spacing-bottom-half;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    margin-bottom: $spacing-bottom-half;

    i,
    h5 {
      color: $green;
    }

    i {
      margin-bottom: 8px;
      font-size: 30px;
    }

    span {
      display: block;
    }
   
    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      span,
      p {
        font-size: 14px;
      }

      h5 {
        font-size: 18px;
      }
    }
  }
}

.flex-icon-cards + .newsletter {
  margin-top: -$spacing-bottom;
}

.content-block + .flex-icon-cards {
  margin-top: -$spacing-bottom-half;
}