.single-product {
  .product-title-wrapper {
    @extend .col-12;
  }

  .summary.entry-summary {
    .product-title-wrapper {
      @include media-breakpoint-down(sm) {
        display: none;        
      }
    }
    .suitability {
      @extend .col-12;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .woocommerce-notices-wrapper {
    display: none;
  }
  
  .type-product {
    margin-bottom: $spacing-bottom;
  }

  .price {
    font-size: 25px;
    font-weight: $fw-regular;

    .regular-price {
      font-size: 16px;
      color: $red;
      font-style: italic;
    }
  }

  .woocommerce-review-link {
    display: none;
  }

  .product-description {
    padding: 12px 0;

    .suitability {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .product-notice {
    position: relative;
    padding: $single-gutter;
    padding-left: 65px;
    border: 1px solid darken($lighter-gray, 8%);
    background-color: darken($lighter-gray, 4%);
    margin-bottom: $single-gutter;

    &:before {
      content: "\f05a";
      @extend .fas;
      font-size: 35px;
      position: absolute;
      opacity: .2;
      top: 10px;
      left: $single-gutter;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .key-benefits {
    background-color: $lighter-gray;
    padding: $single-gutter;
    border-radius: 5px;

    h3 {
      font-size: 25px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

    ul {
      padding: 0 20px;
      margin-bottom: 0;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .stock {
    font-size: 14px;
    color: $red;
    margin-bottom: 10px;
  }

  .variations_form {
    .variations {
      margin-bottom: $single-gutter;
    }

    select {
      width: 100%;
    }

    label {
      width: 85px;
      margin: 0;
    }
  }

  .reset_variations {
    display: none !important;
  }
}

.woocommerce-product-gallery__image {
  margin-bottom: $spacing-bottom-half !important;
}

.suitability {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $single-gutter;

  .scol {
    flex-basis: 62px;

    @include media-breakpoint-down(sm) {
      flex-basis: 65px;
    }
  }
}

.serving-data {
  font-weight: normal;
}

.ingredient-text {
  margin-bottom: $double-gutter;
}

.woocommerce-tabs-mobile {
  margin-bottom: $spacing-bottom-half;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  .product-tab-title {
    background-color: $lighter-gray;
    padding: 5px 0;
    border: 1px solid $light-gray;
    padding-left: $single-gutter;
    padding-right: $single-gutter;
    margin-bottom: 5px;
    font-weight: $fw-semibold;
    position: relative;
    cursor: pointer;

    .fas {
      transition: all .25s ease-in-out;
    }

    &.active {
      .fas {
        transform: rotate(90deg);
      }
    }

    .fas {
      position: absolute;
      right: $single-gutter;
      top: 10px;
    }
  }

  .woocommerce-Tabs-panel-mobile {
    margin-bottom: 20px;
    // display: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;

    h2:first-child {
      display: none;
    }
  }
}

.woocommerce-tabs {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
