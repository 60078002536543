nav.main-nav {
  @include media-breakpoint-down(md) {
    display: none;
  }

  .mega-menu {
    display: none;
    position: absolute;
    left: $single-gutter;
    right: $single-gutter;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .5);
    z-index: 999;
    border-radius: 0 0 5px 5px;

    &-image {
      margin-bottom: $single-gutter;
    }

    a {
      color: $dark-gray;

      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }

      &:hover {
        color: $brand-primary;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    // display: flex;
    // flex-wrap: wrap;
    text-align: right;
    
    li {
      text-align: right;
      margin-left: 6px;
      display: inline-block;
      position: relative;

      .sub-menu {
        display: none;
        background-color: $dark-gray;
        padding: 6px;
        position: absolute;
        width: 200px;
        text-align: left;
        // margin-top: 20px;
        top: 100%;

        a {
          display: block;
        }
      }

      &:hover {
        .sub-menu {
          display: block;
        }
      }

      &:before {
        display: none;
      }

      &:hover {
        // background-color: darken($brand-primary, 5%);
        
        .mega-menu {
          display: block;
          background-color: $white;
          padding: 20px 0;
        }
      }

      a {
        color: $white;
        font-size: 18px;
        padding: 5px;
        display: block;

        &:hover {
          color: $green;
          text-decoration: none;
        }

      }
    }
  }
}

.menu-mobile-menu-container {
  a {
    display: block;
  }
  
  .sub-menu {
    display: none;
    list-style-type: none;
    padding: 15px 15px 0;

    li {
      padding: 3px 0;
    }
  }
}