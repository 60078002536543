.flex-carousel {
	margin-bottom: $spacing-bottom;
	position: relative;

  .swiper-slide {
    min-height: 80vh;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
      min-height: 500px;
    }
  }

	.overlay-color {
		background-color: rgba(59, 59, 59, .5);
	}

	.has-mobile {
		.mobile-img {
			@include media-breakpoint-up(md) {
				display: none !important;
			}
		}

		.desktop-img {
			@include media-breakpoint-down(sm) {
				display: none !important;
			}
		}
	}

	.owl-carousel .owl-stage-outer {
		overflow: hidden;
	}

	.carousel-text {
		position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    font-size: 20px;
    z-index: 10;
    padding-top: 115px;

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
    }

    h1 {
      font-size: 40px;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }
    }


    .container,
    .row {
    	height: 100%;
    }
	}
}

// nav + .flex-carousel,
// header + .flex-carousel {
// 	margin-top: -$spacing-bottom;

// 	@include media-breakpoint-down(md) {
// 		margin-top: -$spacing-bottom-half;
// 	}
// }