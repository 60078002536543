.mailchimp-newsletter,
.create-account {
	margin-bottom: 0;
}

.create-account {
	padding-left: 0;
	padding-right: 0;
}

#sagepaydirect-cc-form .wc-credit-card-form-card-number,
#sagepaydirect-cc-form .wc-credit-card-form-card-expiry,
#sagepaydirect-cc-form .wc-credit-card-form-card-cvc {
	font-size: inherit !important;
	padding: 6px !important;
}

#sagepaydirect-cc-form .wc-credit-card-form-card-type {
	font-size: inherit !important;
	background-position: calc(100% - 15px) center !important;
}

.payment_method_sagepaydirect {
	img {
		margin-right: 5px !important;
	}
}

.woocommerce-shipping-methods {
	label {
    margin-left: 10px;
	}
}

.woocommerce-customer-details {
	.woocommerce-columns {
		@extend .row;
	}
}

.woocommerce-thankyou-order-details {
	padding: 0;
	margin: 0;
	list-style-type: none;
	margin-bottom: $spacing-bottom;
}

.woocommerce-table--order-details {
	@extend .table, .table-striped;
	margin-bottom: $spacing-bottom-half;
}

form.checkout {
	@extend .row;

	.form-row-first,
	.form-row-last {
		@extend .col-12, .col-sm-6;
	}

	.form-row-wide {
		@extend .col-12;
	}

	.col2-set {
		@extend .col-12, .col-sm-6, .col-md-8;
		margin-bottom: $spacing-bottom;

		.col-1,
		.col-2 {
			width: 100%;
			padding-left: 0;
			padding-right: 0;

			.form-row-first,
			.form-row-last {
				@extend .col-12, .col-sm-6;
			}

			.form-row-first {
				padding-right: 15px;
			}

			.form-row-last {
				padding-left: 15px;
			}
		}
	}

	.woocommerce-billing-fields__field-wrapper {
		@extend .row;
	}

	.order_review_wrapper {
		@extend .col-12, .col-sm-6, .col-md-4;
	}

	.woocommerce-NoticeGroup-checkout {
		@extend .col-12;
	}
	
}


.woocommerce-checkout-review-order {
	margin-bottom: $spacing-bottom-half;
	
	table {
		@extend .table;

		th {
			&.product-total {
				text-align: right;
			}
		}

		td {
			text-align: right;

			&.product-name {
				text-align: left;
			}
		}
	}

	.payment_methods {
		list-style-type: none;
		padding: 0;
	}

	.place-order {
		.button {
			@extend .btn-primary;
		}
	}
}


.woocommerce-form-coupon-toggle {
	.woocommerce-info {
		background-color: transparent;
		border-radius: 0;
		border: 1px solid $light-gray;
		color: $dark-gray;
	}
}

.checkout_coupon.woocommerce-form-coupon {
	padding: $single-gutter;
	background-color: $lighter-gray;
	margin-bottom: $spacing-bottom-half;
}

.woocommerce-shipping-fields {
	h3 span {
		font-size: 16px;
    font-weight: normal;
	}
	
	&__field-wrapper {
		@extend .row;
	}
}

form.checkout.woocommerce-checkout {
	.order_review_wrapper {
		.woocommerce-form-coupon-toggle {
			display: none;
		}

		.form-row {
			padding-left: 0;
			padding-right: 0;
	    flex: 0 0 100%;
	  	max-width: 100%;
	  	width: 100%;
		}
	}
}

#wc_checkout_add_ons a.wc-checkout-add-ons-dropzone {
	background-color: $medium-gray !important;
	color: $white;
}