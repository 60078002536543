.woocommerce-info {
	@extend .alert-info, .alert;
	margin-bottom: $single-gutter;

	.button {
		@extend .btn-info, .float-right;
	}
}

.woocommerce-message {
	@extend .alert-success, .alert;
	margin-bottom: $single-gutter;

	.button {
		@extend .btn-success, .float-right, .btn-sm;
	}

	&.woocommerce-info {

		.button {
			@extend .btn-info, .float-right;
		}
	}
}

.product-wrap {
	.woocommerce-notices-wrapper {
		display: none;
	}
}


.woocommerce-error {
	@extend .alert-danger, .alert;
	margin-bottom: $single-gutter;
	list-style-type: none;

	.button {
		@extend .btn-danger, .float-right, .btn-sm;

	}
}