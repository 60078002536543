.testimonial {
  margin-bottom: $spacing-bottom;

  &-item {
    background-color: darken($lightest-gray, 5%);
    padding: $single-gutter;
    border-radius: $single-gutter;
    position: relative;
    padding-left: 80px;

    &:before {
      content: "\201C";
      color: rgba(99, 99, 99, 0.29);
      font-size: 190px;
      position: absolute;
      left: 5px;
      top: -56px;
      font-family: $font-heading;
    }
  }

  &-text {
    margin-bottom: $single-gutter;
    font-style: italic;
    font-size: 22px;
  }

  &-name {
    font-size: 14px;
    text-align: right;
  }
}